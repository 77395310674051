import { useSingleFieldQuery } from "../api";
import { useParams } from "react-router-dom";
import { Table } from "../components/Table";

export const Field = () => {
  const params = useParams();
  const { data } = useSingleFieldQuery({
    variables: {
      // @ts-ignore
      field: params.id,
    },
  });

  if (!data?.field) return <div>Loading</div>;

  return (
    <div className="max-w-7xl mx-auto py-4 px-8 space-y-4">
      <h1 className="text-2xl font-semibold text-gray-900">
        {data.field.name}
      </h1>
      <div className="text-sm">{data.field.description}</div>
      <Table
        data={data.field.trees.slice().sort((a, b) => {
          return a.x >= b.x ? 1 : -1;
        })}
        linkTo={(d: any) => `/trees/${d.id}`}
        columns={[
          {
            label: "Cert.",
            getter: (d: any) => d.certificate,
          },
          {
            label: "Column",
            getter: (d: any) => d.x,
          },
          {
            label: "Row #",
            getter: (d: any) => d.y,
          },
          {
            label: "Variety",
            getter: (d: any) => d.variety,
          },
          {
            label: "State",
            getter: (d: any) => d.state,
          },
          {
            label: "Fungus",
            getter: (d: any) => d.fungus,
          },
        ]}
      />
    </div>
  );
};

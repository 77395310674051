import { useSingleTreeQuery } from "../api";
import { useParams } from "react-router-dom";
import { Table } from "../components/Table";
import { TruffleForm } from "../components/TruffleForm";

const Card = (props: any) => {
  return (
    <div className="py-2 align-middle inline-block min-w-full">
      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white text-sm px-4 px-4 py-5">
        {props.children}
      </div>
    </div>
  );
};

export const Tree = () => {
  const params = useParams();
  const { data } = useSingleTreeQuery({
    variables: {
      // @ts-ignore
      tree: params.id,
    },
  });

  if (!data?.tree) return <div>Loading</div>;

  const Line = ({ label, value }: any) => {
    return (
      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">{label}</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {value}
        </dd>
      </div>
    );
  };

  return (
    <div className="max-w-7xl mx-auto py-4 px-8 space-y-4">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Tree Information
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500 font-mono">
            {data.tree.certificate}
          </p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <Line label="Variety" value={data.tree.variety} />
            <Line label="State" value={data.tree.state} />
            <Line label="Fungus" value={data.tree.fungus} />
            <Line label="Year Of Birth" value={data.tree.yearOfBirth} />
            <Line label="Planted At" value={data.tree.plantedAt} />
            <Line label="Field Column" value={data.tree.x} />
            <Line label="Field Row" value={data.tree.y} />
          </dl>
        </div>
      </div>

      <h2>Truffles</h2>
      <Table
        data={data.tree.truffles}
        columns={[
          {
            label: "Weight",
            getter: (d: any) => d.weight,
          },
          {
            label: "Person",
            getter: (d: any) =>
              [d.discoveredWith.firstName, d.discoveredWith.lastName].join(" "),
          },
          {
            label: "Animal",
            getter: (d: any) => d.discoveredBy.name,
          },
        ]}
      />

      <Card>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Record New Truffle Discovery
        </h3>
        <TruffleForm treeId={data.tree.id} />
      </Card>
    </div>
  );
};

import * as Plot from "@observablehq/plot";
import { useFieldsQuery } from "../api";
import { useEffect, useRef } from "react";
import { useAuth } from "../context/Auth";

export default function Dashboard() {
  const [{ activeAccount }] = useAuth();
  const fieldsData = useFieldsQuery({
    variables: {
      account: activeAccount as string,
    },
  });

  const data = fieldsData?.data?.fields;
  // @ts-ignore
  const truffles = [];

  // @ts-ignore
  for (const field of data) {
    for (const tree of field.trees) {
      truffles.push({
        truffleCount: tree.truffleCount,
        variety: tree.variety,
        state: tree.state,
        fungus: tree.fungus,
        field: field.name,
        x: tree.x,
        y: tree.y,
      });
    }
  }
}

// @ts-ignore
export const TreeHeatmap = ({ data, height, width }) => {
  const containerRef = useRef();

  useEffect(() => {
    // @ts-ignore
    if (data.length === 0) return;
    const plot = Plot.plot({
      height,
      width,
      y: { reverse: true },
      color: {
        scheme: "BuGn",
        type: "sequential",
      },
      marks: [
        Plot.cell(
          // @ts-ignore
          data,
          {
            // @ts-ignore
            fill: "truffleCount",
            x: "x",
            y: "y",
            inset: 0,

            // thresholds: 100,
          }
        ),
        Plot.frame(),
        Plot.tip(
          data,
          Plot.pointer({
            x: "x",
            y: "y",
            filter: (d) => "asdasds",
            title: (d) => {
              return `The tree at row ${d.x} and column ${d.y} has ${d.truffleCount} truffles.              

Variety: ${d.variety}
State: ${d.state}
Fungus: ${d.fungus}
Certificate: ${d.certificate}
Year of Birth: ${d.yearOfBirth}
`;
            },
          })
        ),
      ],
    });
    // @ts-ignore
    containerRef.current.append(plot);
    return () => plot.remove();
  }, [data, height, width]);

  return (
    <div
      // @ts-ignore
      ref={containerRef}
      className="block bg-white"
      style={{
        height,
        width,
      }}
    />
  );
};

// @ts-ignore
export const TruffleDistribution = ({ data, height, width }) => {
  const containerRef = useRef();

  // @ts-ignore
  const truffles = data.flatMap((field) =>
    // @ts-ignore
    field.trees.map((tree) => ({
      truffleCount: tree.truffleCount,
      field: field.name,
      x: tree.x,
      y: tree.y,
      id: tree.id,
    }))
  );

  useEffect(() => {
    // @ts-ignore
    if (truffles.length === 0) return;
    const plot = Plot.plot({
      height,
      width,
      marginBottom: 50,
      y: { grid: true },
      color: { legend: false },
      marks: [
        Plot.rectY(
          truffles,
          // @ts-ignore
          Plot.binX(
            { y: "sum" },
            {
              x: {
                value: "truffleCount",
                thresholds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
              },
            }
          )
        ),
        Plot.ruleY([0]),
      ],
    });
    // @ts-ignore
    containerRef.current.append(plot);
    return () => plot.remove();
  }, [truffles, height, width]);

  return (
    <div
      // @ts-ignore
      ref={containerRef}
      className="block"
      style={{
        height,
        width,
      }}
    />
  );
};

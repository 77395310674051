import { Link } from "react-router-dom";

export function Table(props: any) {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {props.columns.map((col: any) => {
                    return (
                      <th
                        key={col.label}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {col.label}
                      </th>
                    );
                  })}

                  {(props.linkTo || props.onDelete) && (
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {props.data.map((field: any) => {
                  return (
                    <tr key={field.id}>
                      {props.columns.map((col: any, idx: any) => (
                        <td
                          key={idx}
                          className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                        >
                          {col.getter(field)}
                        </td>
                      ))}
                      {(props.linkTo || props.onDelete) && (
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
                        {props.linkTo && (
                          <Link
                            to={props.linkTo(field)}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            View
                          </Link>
                        )}
                                                {props.onDelete && (
                        <>
                          <Link
                            to={props.editTo(field)}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Edit
                          </Link>
      <button
        type="button"
        className="-ml-px relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        onClick={() => props.onDelete(field.id)}
      >
        Delete
      </button>
    </>
                          
                        )}
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

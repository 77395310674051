import React from "react";
import { useAuth } from "context/Auth";
import { Table } from "../components/Table";
import { useFieldsQuery } from "../api";
import { TreeHeatmap } from "components/Viz";
import AutoSizer from "react-virtualized-auto-sizer";

const Card = (props: any) => {
  return (
    <div className="py-2 align-middle inline-block min-w-full">
      <div className="shadow border-b border-gray-200 h-full sm:rounded-lg bg-white text-sm px-4 py-5">
        {props.children}
      </div>
    </div>
  );
};

export const Dashboard = () => {
  const [{ activeAccount }] = useAuth();

  const fieldsData = useFieldsQuery({
    variables: {
      account: activeAccount as string,
    },
  });

  if (!fieldsData?.data?.fields) return <div>Loading</div>;

  return (
    <div className="py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Field Overview</h1>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <Card>
          <p className="mb-2 text-xs">
            Each tree is represented by a cell in its row/col coordinate within
            the field.
          </p>
          <div className="grid grid-cols-2 gap-6">
            {fieldsData.data.fields.map((field) => {
              return (
                <div key={field.id}>
                  <b>{field.name}</b>
                  <div className="w-full h-full block">
                    <AutoSizer disableHeight>
                      {({ width }) => (
                        <div style={{ height: "100%" }}>
                          <TreeHeatmap
                            data={field.trees}
                            height={300}
                            width={width}
                          />
                        </div>
                      )}
                    </AutoSizer>
                  </div>
                </div>
              );
            })}
          </div>
        </Card>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-4">
          <Table
            data={fieldsData.data.fields}
            linkTo={(d: any) => `fields/${d.id}`}
            columns={[
              {
                label: "Name",
                getter: (d: any) => d.name,
              },
              {
                label: "Tree Count",
                getter: (d: any) => d.treeCount,
              },
              {
                label: "Truffle Count",
                getter: (d: any) => d.truffleCount,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

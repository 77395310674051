import { Field, Form, Formik, FormikProps } from "formik";
import { useAuth } from "context/Auth";
import {
  usePeopleQuery,
  useAnimalsQuery,
  useRecordTruffleMutation,
  useUpdateTruffleMutation,
} from "../api";
import { parse } from "date-fns";

const NumericInput = ({ field, form, ...props }: any) => {
  if (props.readOnly) {
    return <span>{field.value}</span>;
  }

  return (
    <input
      type="number"
      name="first_name"
      id="first_name"
      autoComplete="given-name"
      className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
      {...field}
      {...props}
    />
  );
};

const DateInput = ({ field, form, ...props }: any) => {
  if (props.readOnly) {
    return <span>{field.value.toLocaleDateString("en-US")}</span>;
  }
  return (
    <input
      type="date"
      className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
      {...field}
      {...props}
    />
  );
};

const TextArea = ({ field, form, ...props }: any) => {
  if (props.readOnly) {
    return <span>{field.value}</span>;
  }
  return (
    <textarea
      id="about"
      name="about"
      rows={3}
      className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
      {...field}
      {...props}
    />
  );
};

const PersonPicker = ({ field, form, ...props }: any) => {
  const [authState] = useAuth();
  const { data } = usePeopleQuery({
    variables: {
      // @ts-ignore
      account: authState.activeAccount,
    },
  });

  if (!data?.people) return <div>Loading</div>;

  if (props.readOnly) {
    const person = data.people.find((d) => d.id === field.value);
    return (
      <span>
        {(person as any).firstName} {(person as any).lastName}
      </span>
    );
  }

  return (
    <select
      onChange={(value) => form.setFieldValue(field.name, value)}
      onBlur={() => form.setFieldTouched(field.name, true)}
      className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
      {...field}
      {...props}
    >
      <option>-</option>
      {data.people.map((person) => (
        <option key={person.id} value={person.id as string}>
          {person.firstName} {person.lastName}
        </option>
      ))}
    </select>
  );
};

const AnimalPicker = ({ field, form, ...props }: any) => {
  const [authState] = useAuth();
  const { data } = useAnimalsQuery({
    variables: {
      // @ts-ignore
      account: authState.activeAccount,
    },
  });

  if (!data?.animals) return <div>Loading</div>;

  if (props.readOnly) {
    const animal = data.animals.find((d) => d.id === field.value);
    return <span>{(animal as any).name}</span>;
  }

  return (
    <select
      onChange={(value) => form.setFieldValue(field.name, value)}
      onBlur={() => form.setFieldTouched(field.name, true)}
      className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
      {...field}
      {...props}
    >
      <option>-</option>
      {data.animals.map((animal) => (
        <option key={animal.id} value={animal.id as string}>
          {animal.name}
        </option>
      ))}
    </select>
  );
};

export const TruffleForm = (props: any) => {
  const [recordTruffle] = useRecordTruffleMutation();
  const [updateTruffle] = useUpdateTruffleMutation();
  const todaysDate = new Date();

  const parsedDate = props.truffle
    ? props.readOnly
      ? parse(props.truffle.discoveredAt, "yyyy-MM-dd", new Date())
      : props.truffle.discoveredAt
    : todaysDate;
  return (
    <Formik
      initialValues={{
        weight: props.truffle ? props.truffle.weight : 0,
        comments: props.truffle ? props.truffle.comments : 0,
        foundByPerson: props.truffle ? props.truffle.discoveredWith.id : "",
        foundByAnimal: props.truffle ? props.truffle.discoveredBy.id : "",
        dateFound: parsedDate,
      }}
      onSubmit={async (values, actions) => {
        if (props.truffle) {
          try {
            await updateTruffle({
              variables: {
                id: props.truffle.id,
                input: {
                  weight: values.weight,
                  comments: values.comments,
                  foundByAnimal: values.foundByAnimal,
                  foundByPerson: values.foundByPerson,
                  dateOfDiscovery: new Date(values.dateFound).toISOString(),
                },
              },
            });
            actions.setSubmitting(false);
            // @ts-ignore
            window.location.reload();
          } catch (e) {
            // console.log(e);
          }
        } else {
          try {
            await recordTruffle({
              variables: {
                input: {
                  weight: values.weight,
                  comments: values.comments,
                  // @ts-ignore
                  tree: props.treeId,
                  foundByAnimal: values.foundByAnimal,
                  foundByPerson: values.foundByPerson,
                  dateOfDiscovery: new Date(values.dateFound).toISOString(),
                },
              },
            });
            actions.setSubmitting(false);
            // @ts-ignore
            window.location.reload();
          } catch (e) {
            // console.log(e);
          }
        }
      }}
    >
      {(formikProps: FormikProps<any>) => {
        return (
          <Form className="space-y-8 divide-y divide-gray-200">
            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="first_name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Weight
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Field
                    type="number"
                    name="weight"
                    component={NumericInput}
                    readOnly={props.readOnly}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="first_name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Date Found
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Field
                    type="date"
                    name="dateFound"
                    component={DateInput}
                    readOnly={props.readOnly}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="foundByPerson"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Found By (Person)
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Field
                    name="foundByPerson"
                    component={PersonPicker}
                    readOnly={props.readOnly}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="foundByAnimal"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Found By (Animal)
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Field
                    name="foundByAnimal"
                    component={AnimalPicker}
                    readOnly={props.readOnly}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Comments
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Field
                    type="text"
                    name="comments"
                    placeholder="Enter comments here."
                    component={TextArea}
                    readOnly={props.readOnly}
                  />
                </div>
              </div>
            </div>

            {!props.readOnly && (
              <div className="pt-5">
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save
                  </button>
                </div>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

import { useAuth } from "context/Auth";
import { isBefore, parse } from "date-fns";

import {
  TrufflesDocument,
  useDeleteTruffleMutation,
  useTrufflesQuery,
} from "../api";
import { Table } from "../components/Table";

export const Truffle = () => {
  const [authState] = useAuth();

  const { data } = useTrufflesQuery({
    variables: {
      // @ts-ignore
      account: authState.activeAccount,
    },
  });

  const [deleteTruffle] = useDeleteTruffleMutation();

  if (!data?.truffles) return <div>Loading</div>;

  return (
    <div className="max-w-7xl mx-auto py-4 px-8 space-y-4">
      <h1 className="text-2xl font-semibold text-gray-900">Truffles</h1>
      <Table
        data={[...data.truffles].sort(function (a, b) {
          const firstDate = parse(a.discoveredAt, "yyyy-MM-dd", new Date());
          const secondDate = parse(b.discoveredAt, "yyyy-MM-dd", new Date());
          return isBefore(firstDate, secondDate) ? -1 : 1;
        })}
        linkTo={(d: any) => `/truffles/${d.id}`}
        editTo={(d: any) => `/truffles/${d.id}/edit`}
        onDelete={async (id: any) => {
          if (window.confirm("Do you want to delete this truffle?")) {
            await deleteTruffle({
              variables: {
                id,
              },
              refetchQueries: [
                {
                  query: TrufflesDocument,
                  variables: {
                    account: authState.activeAccount,
                  },
                },
              ],
            });
          }
        }}
        columns={[
          {
            label: "Field",
            getter: (d: any) => (
              <div>
                <span>{d.field.name}</span>
                <br />
                <span className="text-xs leading-6 font-medium text-gray-400">
                  {`ROW ${d.tree.x} COL ${d.tree.y}`}
                </span>
              </div>
            ),
          },
          {
            label: "Date",
            getter: (d: any) => `${d.discoveredAt}`,
          },
          {
            label: "Weight",
            getter: (d: any) => d.weight,
          },
          {
            label: "Discoverer",
            getter: (d: any) => (
              <span>
                {d.discoveredBy.name} (with{" "}
                {[d.discoveredWith.firstName, d.discoveredWith.lastName].join(
                  " "
                )}
                )
              </span>
            ),
          },
        ]}
      />
    </div>
  );
};

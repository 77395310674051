import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  Upload: { input: any; output: any; }
};

export type Account = {
  __typename?: 'Account';
  admin: Person;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  people: Array<Person>;
};

export type Animal = {
  __typename?: 'Animal';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  species?: Maybe<AnimalSpecies>;
};

export enum AnimalSpecies {
  Dog = 'DOG',
  Pig = 'PIG'
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type Coordinates = {
  __typename?: 'Coordinates';
  x?: Maybe<Scalars['Int']['output']>;
  y?: Maybe<Scalars['Int']['output']>;
};

export type Credentials = {
  __typename?: 'Credentials';
  emailAddress: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  passwordHash: Scalars['String']['output'];
  person?: Maybe<Person>;
};

export type DiscoveryInput = {
  comments?: InputMaybe<Scalars['String']['input']>;
  dateOfDiscovery: Scalars['DateTime']['input'];
  foundByAnimal: Scalars['String']['input'];
  foundByPerson: Scalars['String']['input'];
  tree: Scalars['String']['input'];
  weight: Scalars['Int']['input'];
};

export type DiscoveryUpdateInput = {
  comments?: InputMaybe<Scalars['String']['input']>;
  dateOfDiscovery: Scalars['DateTime']['input'];
  foundByAnimal: Scalars['String']['input'];
  foundByPerson: Scalars['String']['input'];
  weight: Scalars['Int']['input'];
};

export type Field = {
  __typename?: 'Field';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  treeCount?: Maybe<Scalars['Int']['output']>;
  trees: Array<Tree>;
  truffleCount?: Maybe<Scalars['Int']['output']>;
  truffles: Array<Truffle>;
  wateringHistory: Array<WateringEvent>;
};

export type Mutation = {
  __typename?: 'Mutation';
  deleteTruffle: Scalars['Boolean']['output'];
  recordDiscovery: Truffle;
  updateTruffle: Scalars['Boolean']['output'];
};


export type MutationDeleteTruffleArgs = {
  id: Scalars['String']['input'];
};


export type MutationRecordDiscoveryArgs = {
  input: DiscoveryInput;
};


export type MutationUpdateTruffleArgs = {
  id: Scalars['String']['input'];
  input: DiscoveryUpdateInput;
};

export type Person = {
  __typename?: 'Person';
  credentials?: Maybe<Credentials>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  accessToken: Scalars['String']['output'];
  accounts: Array<UserAccountConnection>;
  animals: Array<Animal>;
  field: Field;
  fields: Array<Field>;
  my: Person;
  people: Array<Person>;
  tree: Tree;
  truffle: Truffle;
  truffles: Array<Truffle>;
};


export type QueryAccessTokenArgs = {
  emailAddress: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type QueryAnimalsArgs = {
  account: Scalars['String']['input'];
};


export type QueryFieldArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFieldsArgs = {
  account?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPeopleArgs = {
  account: Scalars['String']['input'];
};


export type QueryTreeArgs = {
  tree: Scalars['String']['input'];
};


export type QueryTruffleArgs = {
  truffle: Scalars['String']['input'];
};


export type QueryTrufflesArgs = {
  account: Scalars['String']['input'];
};

export enum Role {
  Admin = 'ADMIN',
  User = 'USER',
  Viewer = 'VIEWER'
}

export type Tree = {
  __typename?: 'Tree';
  address?: Maybe<Coordinates>;
  certificate: Scalars['String']['output'];
  field: Field;
  fungus: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  plantedAt?: Maybe<Scalars['DateTime']['output']>;
  plantedBy?: Maybe<Person>;
  state: Scalars['String']['output'];
  truffleCount: Scalars['Int']['output'];
  truffles: Array<Truffle>;
  variety: Scalars['String']['output'];
  x: Scalars['Int']['output'];
  y: Scalars['Int']['output'];
  yearOfBirth: Scalars['Int']['output'];
};

export type Truffle = {
  __typename?: 'Truffle';
  comments?: Maybe<Scalars['String']['output']>;
  discoveredAt: Scalars['Date']['output'];
  discoveredBy?: Maybe<Animal>;
  discoveredWith?: Maybe<Person>;
  field?: Maybe<Field>;
  id?: Maybe<Scalars['String']['output']>;
  tree?: Maybe<Tree>;
  weight?: Maybe<Scalars['Int']['output']>;
};

export type UserAccountConnection = {
  __typename?: 'UserAccountConnection';
  account: Account;
  role: Role;
};

export type WateringEvent = {
  __typename?: 'WateringEvent';
  field?: Maybe<Field>;
  id?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  timestamp?: Maybe<Scalars['DateTime']['output']>;
};

export type AccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountsQuery = { __typename?: 'Query', accounts: Array<{ __typename?: 'UserAccountConnection', account: { __typename?: 'Account', id?: string | null, name?: string | null } }> };

export type AnimalsQueryVariables = Exact<{
  account: Scalars['String']['input'];
}>;


export type AnimalsQuery = { __typename?: 'Query', animals: Array<{ __typename?: 'Animal', id?: string | null, name?: string | null, species?: AnimalSpecies | null }> };

export type DeleteTruffleMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteTruffleMutation = { __typename?: 'Mutation', deleteTruffle: boolean };

export type FieldsQueryVariables = Exact<{
  account: Scalars['String']['input'];
}>;


export type FieldsQuery = { __typename?: 'Query', fields: Array<{ __typename?: 'Field', id?: string | null, description?: string | null, name?: string | null, treeCount?: number | null, truffleCount?: number | null, trees: Array<{ __typename?: 'Tree', x: number, y: number, id?: string | null, truffleCount: number, variety: string, state: string, fungus: string, certificate: string, yearOfBirth: number }> }> };

export type LoginQueryVariables = Exact<{
  emailAddress: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginQuery = { __typename?: 'Query', accessToken: string };

export type PeopleQueryVariables = Exact<{
  account: Scalars['String']['input'];
}>;


export type PeopleQuery = { __typename?: 'Query', people: Array<{ __typename?: 'Person', id?: string | null, firstName?: string | null, lastName?: string | null }> };

export type ProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileQuery = { __typename?: 'Query', my: { __typename?: 'Person', firstName?: string | null, lastName?: string | null, id?: string | null } };

export type RecordTruffleMutationVariables = Exact<{
  input: DiscoveryInput;
}>;


export type RecordTruffleMutation = { __typename?: 'Mutation', recordDiscovery: { __typename?: 'Truffle', id?: string | null } };

export type SingleFieldQueryVariables = Exact<{
  field: Scalars['String']['input'];
}>;


export type SingleFieldQuery = { __typename?: 'Query', field: { __typename?: 'Field', id?: string | null, description?: string | null, name?: string | null, treeCount?: number | null, truffleCount?: number | null, trees: Array<{ __typename?: 'Tree', id?: string | null, variety: string, state: string, fungus: string, plantedAt?: string | null, x: number, y: number, certificate: string }> } };

export type SingleTreeQueryVariables = Exact<{
  tree: Scalars['String']['input'];
}>;


export type SingleTreeQuery = { __typename?: 'Query', tree: { __typename?: 'Tree', id?: string | null, plantedAt?: string | null, variety: string, state: string, fungus: string, x: number, y: number, certificate: string, yearOfBirth: number, truffles: Array<{ __typename?: 'Truffle', id?: string | null, discoveredAt: string, weight?: number | null, comments?: string | null, discoveredBy?: { __typename?: 'Animal', name?: string | null } | null, discoveredWith?: { __typename?: 'Person', firstName?: string | null, lastName?: string | null } | null }> } };

export type SingleTruffleQueryVariables = Exact<{
  truffle: Scalars['String']['input'];
}>;


export type SingleTruffleQuery = { __typename?: 'Query', truffle: { __typename?: 'Truffle', id?: string | null, discoveredAt: string, weight?: number | null, comments?: string | null, field?: { __typename?: 'Field', id?: string | null, name?: string | null } | null, tree?: { __typename?: 'Tree', id?: string | null, x: number, y: number } | null, discoveredBy?: { __typename?: 'Animal', id?: string | null, name?: string | null } | null, discoveredWith?: { __typename?: 'Person', id?: string | null, firstName?: string | null, lastName?: string | null } | null } };

export type TrufflesQueryVariables = Exact<{
  account: Scalars['String']['input'];
}>;


export type TrufflesQuery = { __typename?: 'Query', truffles: Array<{ __typename?: 'Truffle', id?: string | null, discoveredAt: string, weight?: number | null, comments?: string | null, field?: { __typename?: 'Field', id?: string | null, name?: string | null } | null, tree?: { __typename?: 'Tree', id?: string | null, x: number, y: number } | null, discoveredBy?: { __typename?: 'Animal', id?: string | null, name?: string | null } | null, discoveredWith?: { __typename?: 'Person', id?: string | null, firstName?: string | null, lastName?: string | null } | null }> };

export type UpdateTruffleMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: DiscoveryUpdateInput;
}>;


export type UpdateTruffleMutation = { __typename?: 'Mutation', updateTruffle: boolean };


export const AccountsDocument = gql`
    query Accounts {
  accounts {
    account {
      id
      name
    }
  }
}
    `;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountsQuery(baseOptions?: Apollo.QueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
      }
export function useAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
        }
export function useAccountsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
        }
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<typeof useAccountsLazyQuery>;
export type AccountsSuspenseQueryHookResult = ReturnType<typeof useAccountsSuspenseQuery>;
export type AccountsQueryResult = Apollo.QueryResult<AccountsQuery, AccountsQueryVariables>;
export const AnimalsDocument = gql`
    query Animals($account: String!) {
  animals(account: $account) {
    id
    name
    species
  }
}
    `;

/**
 * __useAnimalsQuery__
 *
 * To run a query within a React component, call `useAnimalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnimalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnimalsQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useAnimalsQuery(baseOptions: Apollo.QueryHookOptions<AnimalsQuery, AnimalsQueryVariables> & ({ variables: AnimalsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnimalsQuery, AnimalsQueryVariables>(AnimalsDocument, options);
      }
export function useAnimalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnimalsQuery, AnimalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnimalsQuery, AnimalsQueryVariables>(AnimalsDocument, options);
        }
export function useAnimalsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AnimalsQuery, AnimalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AnimalsQuery, AnimalsQueryVariables>(AnimalsDocument, options);
        }
export type AnimalsQueryHookResult = ReturnType<typeof useAnimalsQuery>;
export type AnimalsLazyQueryHookResult = ReturnType<typeof useAnimalsLazyQuery>;
export type AnimalsSuspenseQueryHookResult = ReturnType<typeof useAnimalsSuspenseQuery>;
export type AnimalsQueryResult = Apollo.QueryResult<AnimalsQuery, AnimalsQueryVariables>;
export const DeleteTruffleDocument = gql`
    mutation DeleteTruffle($id: String!) {
  deleteTruffle(id: $id)
}
    `;
export type DeleteTruffleMutationFn = Apollo.MutationFunction<DeleteTruffleMutation, DeleteTruffleMutationVariables>;

/**
 * __useDeleteTruffleMutation__
 *
 * To run a mutation, you first call `useDeleteTruffleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTruffleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTruffleMutation, { data, loading, error }] = useDeleteTruffleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTruffleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTruffleMutation, DeleteTruffleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTruffleMutation, DeleteTruffleMutationVariables>(DeleteTruffleDocument, options);
      }
export type DeleteTruffleMutationHookResult = ReturnType<typeof useDeleteTruffleMutation>;
export type DeleteTruffleMutationResult = Apollo.MutationResult<DeleteTruffleMutation>;
export type DeleteTruffleMutationOptions = Apollo.BaseMutationOptions<DeleteTruffleMutation, DeleteTruffleMutationVariables>;
export const FieldsDocument = gql`
    query Fields($account: String!) {
  fields(account: $account) {
    id
    description
    name
    treeCount
    truffleCount
    trees {
      x
      y
      id
      truffleCount
      variety
      state
      fungus
      certificate
      yearOfBirth
    }
  }
}
    `;

/**
 * __useFieldsQuery__
 *
 * To run a query within a React component, call `useFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldsQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useFieldsQuery(baseOptions: Apollo.QueryHookOptions<FieldsQuery, FieldsQueryVariables> & ({ variables: FieldsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FieldsQuery, FieldsQueryVariables>(FieldsDocument, options);
      }
export function useFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FieldsQuery, FieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FieldsQuery, FieldsQueryVariables>(FieldsDocument, options);
        }
export function useFieldsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FieldsQuery, FieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FieldsQuery, FieldsQueryVariables>(FieldsDocument, options);
        }
export type FieldsQueryHookResult = ReturnType<typeof useFieldsQuery>;
export type FieldsLazyQueryHookResult = ReturnType<typeof useFieldsLazyQuery>;
export type FieldsSuspenseQueryHookResult = ReturnType<typeof useFieldsSuspenseQuery>;
export type FieldsQueryResult = Apollo.QueryResult<FieldsQuery, FieldsQueryVariables>;
export const LoginDocument = gql`
    query Login($emailAddress: String!, $password: String!) {
  accessToken(emailAddress: $emailAddress, password: $password)
}
    `;

/**
 * __useLoginQuery__
 *
 * To run a query within a React component, call `useLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginQuery({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginQuery(baseOptions: Apollo.QueryHookOptions<LoginQuery, LoginQueryVariables> & ({ variables: LoginQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
      }
export function useLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginQuery, LoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
        }
export function useLoginSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LoginQuery, LoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
        }
export type LoginQueryHookResult = ReturnType<typeof useLoginQuery>;
export type LoginLazyQueryHookResult = ReturnType<typeof useLoginLazyQuery>;
export type LoginSuspenseQueryHookResult = ReturnType<typeof useLoginSuspenseQuery>;
export type LoginQueryResult = Apollo.QueryResult<LoginQuery, LoginQueryVariables>;
export const PeopleDocument = gql`
    query People($account: String!) {
  people(account: $account) {
    id
    firstName
    lastName
  }
}
    `;

/**
 * __usePeopleQuery__
 *
 * To run a query within a React component, call `usePeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeopleQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function usePeopleQuery(baseOptions: Apollo.QueryHookOptions<PeopleQuery, PeopleQueryVariables> & ({ variables: PeopleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PeopleQuery, PeopleQueryVariables>(PeopleDocument, options);
      }
export function usePeopleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PeopleQuery, PeopleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PeopleQuery, PeopleQueryVariables>(PeopleDocument, options);
        }
export function usePeopleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PeopleQuery, PeopleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PeopleQuery, PeopleQueryVariables>(PeopleDocument, options);
        }
export type PeopleQueryHookResult = ReturnType<typeof usePeopleQuery>;
export type PeopleLazyQueryHookResult = ReturnType<typeof usePeopleLazyQuery>;
export type PeopleSuspenseQueryHookResult = ReturnType<typeof usePeopleSuspenseQuery>;
export type PeopleQueryResult = Apollo.QueryResult<PeopleQuery, PeopleQueryVariables>;
export const ProfileDocument = gql`
    query Profile {
  my {
    firstName
    lastName
    id
  }
}
    `;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileQuery(baseOptions?: Apollo.QueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
      }
export function useProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
        }
export function useProfileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
        }
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileSuspenseQueryHookResult = ReturnType<typeof useProfileSuspenseQuery>;
export type ProfileQueryResult = Apollo.QueryResult<ProfileQuery, ProfileQueryVariables>;
export const RecordTruffleDocument = gql`
    mutation RecordTruffle($input: DiscoveryInput!) {
  recordDiscovery(input: $input) {
    id
  }
}
    `;
export type RecordTruffleMutationFn = Apollo.MutationFunction<RecordTruffleMutation, RecordTruffleMutationVariables>;

/**
 * __useRecordTruffleMutation__
 *
 * To run a mutation, you first call `useRecordTruffleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordTruffleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordTruffleMutation, { data, loading, error }] = useRecordTruffleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecordTruffleMutation(baseOptions?: Apollo.MutationHookOptions<RecordTruffleMutation, RecordTruffleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecordTruffleMutation, RecordTruffleMutationVariables>(RecordTruffleDocument, options);
      }
export type RecordTruffleMutationHookResult = ReturnType<typeof useRecordTruffleMutation>;
export type RecordTruffleMutationResult = Apollo.MutationResult<RecordTruffleMutation>;
export type RecordTruffleMutationOptions = Apollo.BaseMutationOptions<RecordTruffleMutation, RecordTruffleMutationVariables>;
export const SingleFieldDocument = gql`
    query SingleField($field: String!) {
  field(field: $field) {
    id
    description
    name
    treeCount
    truffleCount
    trees {
      id
      variety
      state
      fungus
      plantedAt
      x
      y
      certificate
    }
  }
}
    `;

/**
 * __useSingleFieldQuery__
 *
 * To run a query within a React component, call `useSingleFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleFieldQuery({
 *   variables: {
 *      field: // value for 'field'
 *   },
 * });
 */
export function useSingleFieldQuery(baseOptions: Apollo.QueryHookOptions<SingleFieldQuery, SingleFieldQueryVariables> & ({ variables: SingleFieldQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SingleFieldQuery, SingleFieldQueryVariables>(SingleFieldDocument, options);
      }
export function useSingleFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SingleFieldQuery, SingleFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SingleFieldQuery, SingleFieldQueryVariables>(SingleFieldDocument, options);
        }
export function useSingleFieldSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SingleFieldQuery, SingleFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SingleFieldQuery, SingleFieldQueryVariables>(SingleFieldDocument, options);
        }
export type SingleFieldQueryHookResult = ReturnType<typeof useSingleFieldQuery>;
export type SingleFieldLazyQueryHookResult = ReturnType<typeof useSingleFieldLazyQuery>;
export type SingleFieldSuspenseQueryHookResult = ReturnType<typeof useSingleFieldSuspenseQuery>;
export type SingleFieldQueryResult = Apollo.QueryResult<SingleFieldQuery, SingleFieldQueryVariables>;
export const SingleTreeDocument = gql`
    query SingleTree($tree: String!) {
  tree(tree: $tree) {
    id
    plantedAt
    variety
    state
    fungus
    x
    y
    certificate
    yearOfBirth
    truffles {
      id
      discoveredAt
      weight
      comments
      discoveredBy {
        name
      }
      discoveredWith {
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useSingleTreeQuery__
 *
 * To run a query within a React component, call `useSingleTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleTreeQuery({
 *   variables: {
 *      tree: // value for 'tree'
 *   },
 * });
 */
export function useSingleTreeQuery(baseOptions: Apollo.QueryHookOptions<SingleTreeQuery, SingleTreeQueryVariables> & ({ variables: SingleTreeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SingleTreeQuery, SingleTreeQueryVariables>(SingleTreeDocument, options);
      }
export function useSingleTreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SingleTreeQuery, SingleTreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SingleTreeQuery, SingleTreeQueryVariables>(SingleTreeDocument, options);
        }
export function useSingleTreeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SingleTreeQuery, SingleTreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SingleTreeQuery, SingleTreeQueryVariables>(SingleTreeDocument, options);
        }
export type SingleTreeQueryHookResult = ReturnType<typeof useSingleTreeQuery>;
export type SingleTreeLazyQueryHookResult = ReturnType<typeof useSingleTreeLazyQuery>;
export type SingleTreeSuspenseQueryHookResult = ReturnType<typeof useSingleTreeSuspenseQuery>;
export type SingleTreeQueryResult = Apollo.QueryResult<SingleTreeQuery, SingleTreeQueryVariables>;
export const SingleTruffleDocument = gql`
    query SingleTruffle($truffle: String!) {
  truffle(truffle: $truffle) {
    id
    field {
      id
      name
    }
    tree {
      id
      x
      y
    }
    discoveredAt
    discoveredBy {
      id
      name
    }
    discoveredWith {
      id
      firstName
      lastName
    }
    weight
    comments
  }
}
    `;

/**
 * __useSingleTruffleQuery__
 *
 * To run a query within a React component, call `useSingleTruffleQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleTruffleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleTruffleQuery({
 *   variables: {
 *      truffle: // value for 'truffle'
 *   },
 * });
 */
export function useSingleTruffleQuery(baseOptions: Apollo.QueryHookOptions<SingleTruffleQuery, SingleTruffleQueryVariables> & ({ variables: SingleTruffleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SingleTruffleQuery, SingleTruffleQueryVariables>(SingleTruffleDocument, options);
      }
export function useSingleTruffleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SingleTruffleQuery, SingleTruffleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SingleTruffleQuery, SingleTruffleQueryVariables>(SingleTruffleDocument, options);
        }
export function useSingleTruffleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SingleTruffleQuery, SingleTruffleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SingleTruffleQuery, SingleTruffleQueryVariables>(SingleTruffleDocument, options);
        }
export type SingleTruffleQueryHookResult = ReturnType<typeof useSingleTruffleQuery>;
export type SingleTruffleLazyQueryHookResult = ReturnType<typeof useSingleTruffleLazyQuery>;
export type SingleTruffleSuspenseQueryHookResult = ReturnType<typeof useSingleTruffleSuspenseQuery>;
export type SingleTruffleQueryResult = Apollo.QueryResult<SingleTruffleQuery, SingleTruffleQueryVariables>;
export const TrufflesDocument = gql`
    query Truffles($account: String!) {
  truffles(account: $account) {
    id
    field {
      id
      name
    }
    tree {
      id
      x
      y
    }
    discoveredAt
    discoveredBy {
      id
      name
    }
    discoveredWith {
      id
      firstName
      lastName
    }
    weight
    comments
  }
}
    `;

/**
 * __useTrufflesQuery__
 *
 * To run a query within a React component, call `useTrufflesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrufflesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrufflesQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useTrufflesQuery(baseOptions: Apollo.QueryHookOptions<TrufflesQuery, TrufflesQueryVariables> & ({ variables: TrufflesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrufflesQuery, TrufflesQueryVariables>(TrufflesDocument, options);
      }
export function useTrufflesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrufflesQuery, TrufflesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrufflesQuery, TrufflesQueryVariables>(TrufflesDocument, options);
        }
export function useTrufflesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TrufflesQuery, TrufflesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TrufflesQuery, TrufflesQueryVariables>(TrufflesDocument, options);
        }
export type TrufflesQueryHookResult = ReturnType<typeof useTrufflesQuery>;
export type TrufflesLazyQueryHookResult = ReturnType<typeof useTrufflesLazyQuery>;
export type TrufflesSuspenseQueryHookResult = ReturnType<typeof useTrufflesSuspenseQuery>;
export type TrufflesQueryResult = Apollo.QueryResult<TrufflesQuery, TrufflesQueryVariables>;
export const UpdateTruffleDocument = gql`
    mutation UpdateTruffle($id: String!, $input: DiscoveryUpdateInput!) {
  updateTruffle(id: $id, input: $input)
}
    `;
export type UpdateTruffleMutationFn = Apollo.MutationFunction<UpdateTruffleMutation, UpdateTruffleMutationVariables>;

/**
 * __useUpdateTruffleMutation__
 *
 * To run a mutation, you first call `useUpdateTruffleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTruffleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTruffleMutation, { data, loading, error }] = useUpdateTruffleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTruffleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTruffleMutation, UpdateTruffleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTruffleMutation, UpdateTruffleMutationVariables>(UpdateTruffleDocument, options);
      }
export type UpdateTruffleMutationHookResult = ReturnType<typeof useUpdateTruffleMutation>;
export type UpdateTruffleMutationResult = Apollo.MutationResult<UpdateTruffleMutation>;
export type UpdateTruffleMutationOptions = Apollo.BaseMutationOptions<UpdateTruffleMutation, UpdateTruffleMutationVariables>;
import React from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createRoot } from "react-dom/client";

import "./styles/index.css";
import * as serviceWorker from "./serviceWorker";
import { App } from "./App";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { HttpLink } from "@apollo/client/link/http";

Sentry.init({
  dsn: "https://0a7a49d839af4322a1b263bfa67b3d7e@o1191760.ingest.sentry.io/6313255",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const isProduction = process.env.NODE_ENV === "production";
const apiHost = process.env.REACT_APP_API_HOST || "http://localhost:8080";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = sessionStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const httpLink = new HttpLink({
  uri: apiHost,
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (networkError && !isProduction)
    console.log(`[Network error]: ${networkError}`);

  const wasUnauthorized =
    graphQLErrors &&
    graphQLErrors.some((e) => {
      return e?.extensions?.code === "UNAUTHENTICATED";
    });

  if (wasUnauthorized && window.location.pathname !== "/login") {
    sessionStorage.removeItem("token");
    window.location.href = "/login";
  }

  if (graphQLErrors && !isProduction)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
});

const client = new ApolloClient({
  // @ts-ignore
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
});

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);

serviceWorker.unregister();

import { useEffect } from "react";
import { useAuth } from "../context/Auth";
import { Navigate } from "react-router-dom";

export function Logout() {
  // eslint-disable-next-line
  const [authState, authDispatch] = useAuth();

  useEffect(() => {
    authDispatch({ type: "logout" });
  });

  return <Navigate to="/login" />;
}

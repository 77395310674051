import { useSingleTruffleQuery } from "../api";
import { useParams } from "react-router-dom";

import { TruffleForm } from "../components/TruffleForm";

const Card = (props: any) => {
  return (
    <div className="py-2 align-middle inline-block min-w-full">
      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white text-sm px-4 py-5">
        {props.children}
      </div>
    </div>
  );
};

export const SingleTruffle = ({ editMode }: { editMode?: boolean }) => {
  const params = useParams();

  const { data } = useSingleTruffleQuery({
    variables: {
      // @ts-ignore
      truffle: params.id,
    },
  });

  if (!data?.truffle) return <div>Loading</div>;

  return (
    <div className="max-w-7xl mx-auto py-4 px-8 space-y-4">
      <Card>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Truffle Details
        </h3>
        <h3 className="text-xs leading-6 font-medium text-gray-600">
          TMS ID: {data.truffle.id}
        </h3>
        <TruffleForm readOnly={!editMode} truffle={data.truffle} />
      </Card>
    </div>
  );
};

import React from "react";
import { jwtDecode } from "jwt-decode";

type Action =
  | { type: "login"; token: string }
  | { type: "logout" }
  | { type: "set_account"; account: string };

type Dispatch = (action: Action) => void;
type State = {
  loggedIn: boolean;
  activeAccount?: string;
  accounts: Array<string>;
};
type AuthProviderProps = { children: React.ReactNode };

const AuthStateContext = React.createContext<State | undefined>(undefined);
const AuthDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

const authReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "login": {
      sessionStorage.setItem("token", action.token);
      // @ts-ignore
      return {
        ...state,
        loggedIn: true,
        // @ts-ignore
        accounts: jwtDecode(action.token).accounts,
        // @ts-ignore
        activeAccount: jwtDecode(action.token).accounts[0],
      };
    }
    case "logout": {
      debugger;
      sessionStorage.clear();
      return {
        ...state,
        loggedIn: false,
        accounts: [],
        activeAccount: undefined,
      };
    }
    case "set_account": {
      return {
        ...state,
        activeAccount: action.account,
      };
    }
    default: {
      throw new Error("Unhandled action type.");
    }
  }
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = React.useReducer(authReducer, {
    loggedIn: !!sessionStorage.getItem("token"),
    accounts: !!sessionStorage.getItem("token")
      ? // @ts-ignore
        jwtDecode(sessionStorage.getItem("token")).accounts
      : [],
    activeAccount: !!sessionStorage.getItem("token")
      ? // @ts-ignore
        jwtDecode(sessionStorage.getItem("token"))?.accounts[0] ?? undefined
      : undefined,
  });
  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

const useAuthState = () => {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }
  return context;
};

const useAuthDispatch = () => {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a AuthProvider");
  }
  return context;
};

export const useAuth = (): [State, Dispatch] => [
  useAuthState(),
  useAuthDispatch(),
];

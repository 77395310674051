import { Routes, Route } from "react-router-dom";
import { HomeIcon, QueueListIcon } from "@heroicons/react/24/outline";
import { AccountSwitcher } from "../components/AccountSwitcher";
import { Dashboard } from "./Dashboard";
import { Field } from "./Field";
import { Tree } from "./Tree";
import { Truffle } from "./Truffle";
import { SingleTruffle } from "./SingleTruffle";
import { NavLink } from "react-router-dom";

function classNames(...classes: Array<String>) {
  return classes.filter(Boolean).join(" ");
}

function AppLink({ label, to, icon: Icon, activeOnlyWhenExact }: any) {
  return (
    <NavLink
      to={to}
      // @ts-ignore
      className={({ isActive, isPending }) => {
        return isActive
          ? "bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
          : "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md";
      }}
    >
      <Icon
        className={classNames(
          true ? "text-gray-300" : "text-gray-400 group-hover:text-gray-300",
          "mr-3 h-6 w-6"
        )}
        aria-hidden="true"
      />
      {label}
    </NavLink>
  );
}

export function Home() {
  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col h-0 flex-1 bg-gray-800">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <nav className="flex-1 px-2 bg-gray-800 space-y-1">
                <div className="mb-4">
                  <AccountSwitcher />
                </div>
                <AppLink
                  activeOnlyWhenExact
                  icon={HomeIcon}
                  label="Field Overview"
                  to="/"
                />
                <AppLink
                  icon={QueueListIcon}
                  label="View All Truffles"
                  to="/truffles"
                />
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <Routes>
            <Route path={"/"} element={<Dashboard />} />
            <Route path={`/fields/:id`} element={<Field />} />

            <Route
              path={`/truffles/:id/edit`}
              element={<SingleTruffle editMode />}
            />
            <Route path={`/truffles/:id`} element={<SingleTruffle />} />
            <Route path={"/truffles"} element={<Truffle />} />
            <Route path={`/trees/:id`} element={<Tree />} />
          </Routes>
        </main>
      </div>
    </div>
  );
}
